app.factory('product', ['$http','Config',function($http,Config) {

	var factoryFns =  {
	  getProducts: function(sectionType,width,height) {
	  	if(sectionType=='category'){
			slug = angular.element('#slug').val();
			query_string = '?brand='+Config.brand_key+'&slug='+slug+'&width='+width+'&height='+height+'&type='+sectionType+'&attributes=key_feature|tech_spec';
			apiSegment = 'products';
		}else if(sectionType=='search'){
			searchTerm = angular.element('#search_term_value').val();
			query_string = '?brand='+Config.brand_key+'&term='+searchTerm+'&width='+width+'&height='+height+'&attributes=key_feature|tech_spec';
			apiSegment = 'search';
		}
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+apiSegment+query_string,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  getProduct: function(width,height,zoom_height,zoom_width){
	  	productSlug = angular.element('#product_slug').val();
		query_string = '?brand='+Config.brand_key+'&product-slug='+productSlug+'&section-type=category&width='+width+'&height='+height+'&zoom-width='+zoom_width+'&zoom-height='+zoom_height+'&attributes=key_feature';
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'product'+query_string,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  getProductGroups: function(width,height,limit){
	  	query_string = '?brand='+Config.brand_key+'&group-types=new_arrivals|featured|highest_rated&limit='+limit+'&width='+width+'&height='+height;
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'products'+query_string,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  ratingFilter: function(rating){

	  	var productRating = [];
		if(!rating){rating = 0;}
		
		var rounded_rating = (Math.round(rating * 2)) / 2;
		var full_star_count = Math.floor(rounded_rating);
		var no_star_count = Math.floor(5 - rounded_rating);
		var half_star_count = 5 - full_star_count - no_star_count;

		for (var i = 0; i < full_star_count; i++) {
			productRating.push(1);
		}
		for (var i = 0; i < half_star_count; i++) {
			productRating.push(0.5);
		}
		for (var i = 0; i < no_star_count; i++) {
			productRating.push(0);
		}
		return productRating;
	  }
	};

	return factoryFns;

}]);
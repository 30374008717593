app.controller("TopCartController", [
  "$scope",
  "Config",
  "cart",
  "toaster",
  "$window",
  function ($scope, Config, cart, toaster, $window) {
    $scope.cart = cart.getCart();
    $scope.base_url = Config.base_url;
    $scope.rates = {};

    $scope.init = function () {
      $scope.rates = {
        vatRate: Config.vatRate,
      };
    };
    $scope.remove = function (idx) {
      var product_name = $scope.cart.content[idx].data.product_name;
      cart.remove(idx);
      toaster.pop(
        "success",
        "Quote Updated!",
        product_name + " removed successfully from quote!"
      );
    };

    ($scope.getQuote = function () {
      $window.location.href = $scope.base_url + "checkout/details";
    }),
      ($scope.switchVAT = function () {
        $scope.cart.VATFlag = cart.switchVAT();
        $(".vat-toggle").bootstrapToggle("toggle");
      });
  },
]);

app.controller('SidebarController', ['$scope','$http','Config','helper', function($scope,$http,Config,helper){

	$scope.postcodeChecker = {
		postcode:''
	}
	$scope.result = null;

	$scope.checkPostcode = function(form){
		if(form.$valid){
			helper.showTransLoader();
			//send request to API to check the delivery price for this postcode
			$http({
			  method: 'GET',
			  url: Config.api_url+'quote/postcode-checker?brand='+Config.brand_key+'&delivery-postcode='+$scope.postcodeChecker.postcode+'&collection-postcode='+$scope.postcodeChecker.postcode+'&cart-total-weight=0',
			  headers:{
			  	'authorization':Config.api_key
			  }
			}).then(function(response) {
				if(response.data.delivery_zone_status=='enabled'){
				   	if(response.data.delivery_charge==0){
				   		$scope.result = 'We are pleased to confirm that we deliver to your area free of charge.';
				   	}else{
				   		$scope.result = 'We are pleased to confirm that we deliver to your area from as little as £'+response.data.delivery_charge+'.';
				   	}
				}else{
					$scope.result = 'We\'d like to provide an exact price for delivery to this postcode, please contact us.';
				}
				helper.hideTransLoader();
			  }, function(response) {
			    console.log(response);
			  });
		}
	}

}]);
$(document).ready(function(e) {

    if($('#consent').val()=='enabled' && !$('#crawler').val()){
        //$('.modal-backdrop').fadeTo(400,0.4);
        // $('#consent_modal').modal({
        //     backdrop: 'static',
        //     keyboard: false,
        //     show:true
        // });
        $('#consent_topbar').show();
    }

    $('.privacy_setting_btn').click(function(){
        $('.modal-backdrop').fadeTo(400,0.4);
        $('#consent_detailed_modal').modal({
            keyboard: false,
            show:true
        });

        $('.modal-backdrop').click(function() {
            $('.modal, .modal-backdrop').fadeOut(400);
            $('#consent_detailed_modal').modal('hide');
        });
    });

    $('#consent_detailed_modal').on('show.bs.modal', function (e) {

      $('#save_consent_options_cancel').click(function(){
        $('.modal-backdrop').fadeOut(400);
        $('#consent_detailed_modal').modal('hide');
      });

      $('#save_consent_options').click(function(){

          //delete all cookies already there
          removeCookies();

          $('#consent_detailed_modal').modal('hide');
          $('.modal-backdrop').fadeOut(400);

        $.ajax({
                type:     'POST',
                url:     $('#base_url').val()+'/consent/choose?token='+$('#user_token').val(),
                data:{
                    'analytics':$('#analytics')[0].checked,
                    'chat':$('#chat')[0].checked,
                    'youtube':$('#youtube')[0].checked/*,
                    'remarketing':$('#remarketing')[0].checked*/
                },dataType:   'json',
                beforeSend : function (){
                    //display the loader
                    $('#trans-loader-wrapper').show();
                },
                success:  function(json){
                    if(json.status=='success'){
                        
                        //on success redirect to the requested category
                        location.reload();
                    }
                }
            });    
        });

    });

    // $('#let_me_choose_btn').click(function(){
    //     $('#consent_topbar').modal('hide');
    //     $('#consent_detailed_modal').modal({
    //         backdrop: 'static',
    //         keyboard: false,
    //         show:true
    //     });
    // });


    $('#consent_continue_btn').click(function(){
        $('#consent_topbar').modal('hide');
        $('.modal-backdrop').fadeOut(400);
        $.ajax({
            type:       'GET',
            url:        $('#base_url').val()+'/consent/agree-all?token='+$('#user_token').val(),
            dataType:   'json',
            beforeSend : function (){
                //display the loader
                $('#trans-loader-wrapper').show();
            },
            success:  function(json){
                    if(json.status=='success'){
                    //on success redirect to the requested category
                    location.reload();
                }
            }
        });    
    });
});


function removeCookies() {

    var domains = [ '.bing.com',
                    '.doubleclick.net',
                    '.linenrental.co.uk',
                    '.google.co.uk',
                    '.google.com',
                    // '.tawk.to',
                    'bat.bing.com',
                    'www.linenrental.co.uk',
                    'www.google.co.uk',
                    // 'va.tawk.to',
                    '.youtube.com',
                    '.linenrental.local',
                    'linenrental.local',
                    ''];
    var cookies = Cookies.get();
    for (var cookie in cookies) {
        for(key in domains){
               Cookies.remove(cookie, { path: '/', domain: domains[key]  }); 
        }
    }
}

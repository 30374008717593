app.controller('MainController', ['$rootScope','$scope','Config', '$window', '$http', 'toaster',
    function($rootScope, $scope, Config, $window, $http, toaster){

        var requestCallback = { // Request Callback
            name: '',
            telephone: '',
            company: '',
            time: ''
        };

        $scope.base_url = Config.base_url;
        $scope.rc = angular.copy(requestCallback);
        $scope.rc.token = angular.element('#user_token').val();
        $scope.rcError = {};


        $scope.requestCallbackSubmit = function($form) {
            if ($scope.rc.name.length <= 3) {
                $scope.rcError.name = true;
                return;
            }
            if ($scope.rc.telephone.length < 8 || $window.isNaN($scope.rc.telephone)) {
                $scope.rcError.telephone = true;
                return;
            }
            if (!$scope.rc.time) {
                $scope.rcError.time = true;
                return;
            }

            $scope.rc.brand = Config.brand_key;

            $http({
                url: Config.api_url + 'callback/request',
                data: $scope.rc,
                method: 'post',
                headers:{
                    'authorization':Config.api_key
                }
            }).then(function(response) {

                if (response.data.success == 1) {
                    //$scope.rcError.agent   = response.data.responseData.agent;
                    $form.$setPristine();
                    $form.$setUntouched();
                    $scope.rc = angular.copy(requestCallback);
                    angular.element('.modal-backdrop').trigger('click');
                    toaster.pop({
                        type: 'success',
                        title:'Thank you!',
                        body: 'An agent will get in touch with you soon!',
                        tapToDismiss: true,
                        timeout: 6000 // closes after 6 seconds.
                    });
                    // ga('send', 'pageview', {page: '/callback.html'});
                    gtag('event', 'page_view', {page_location: Config.base_url + 'callback.html'});
                } else {
                    $scope.rcError.message = response.data.responseData.message;
                }

            }, function(errorResponse) {

            });
        }

    }]);